import React, { useState, useEffect, Link } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useModalStore } from "store";
import SectionHeading from "./common/SectionHeading";
import ProductBox from "./home/ProductBox";
import TopSearch from "./home/TopSearch";
import ProductModal from "./modals/ProductModal";
import { useRecoilValue } from "recoil";
import { bannersListState } from "store/meta";
import { STORAGE_PATH } from "../constant"

const Index = ({ history }) => {
  const showModal = useModalStore((state) => state.showModal);
  const banners = useRecoilValue(bannersListState);

  const [currentEvents, setCurrentEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  useEffect(() => {
    if (banners && banners.length > 0) {
      // console.log("bannersbannersbannersbanners", banners)
      const specialPromos = banners.filter(banner => banner.status === 'ACTIVE');
      if (specialPromos && specialPromos.length > 0) {
        setCurrentEvents(specialPromos);
      }
      const specialMenus = banners.filter(banner => banner.status === 'FUTURE');
      if (specialMenus && specialMenus.length > 0) {
        setUpcomingEvents(specialMenus);
      }
      const shortCutImgs = banners.filter(banner => banner.status === 'EXPIRED');
      if (shortCutImgs && shortCutImgs.length > 0) {
        setPastEvents(shortCutImgs);
      }
      // const onPageLoad = banners.find(banner => banner.type === 'ON_PAGE_LOAD');
      // if(onPageLoad && onPageLoad.image){
      //   showModal(
      //     <ProductModal title="" image={STORAGE_PATH + "/" + onPageLoad.image}/>,
      //     { centered: true }
      //   );
      // }
    }
  }, [banners]);

  const handleMenuOnClick = (product) => {
    // if (product && product.open_type === 'POPUP') {
    //   showModal(
    //     <ProductModal title="Special Menu Cards" image={STORAGE_PATH + "/" + product.image} />,
    //     { centered: true }
    //   );
    // } else if (product && product.open_type === 'URL') {
    //   window.open(product.url);
    // }

    history.push(`/event/${product.id}`);
  };


  return (
    <>
      <TopSearch />

      {currentEvents?.length > 0 && (
        <section className="section pt-5 pb-5 homepage-add-section" id="recent">
          <Container>
            <SectionHeading
              heading="RECENT EVENTS"
              subHeading=""
            />
            <Row className="justify-content-center">
              {currentEvents.map((menu, index) => {
                return (
                  <Col md={8} xs={12} key={index}>
                    <div className="pb-2">
                      <ProductBox
                        id={menu.id}
                        onClick={() => handleMenuOnClick(menu)}
                        image={STORAGE_PATH + "/" + menu.image}
                        imageClass="img-fluid rounded-lg shadow"
                        imageAlt="product"
                        linkUrl="#"
                        // title="Oktoberfest 2023"
                        // boxClass="rounded-lg shadow"
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>
      )}

      {upcomingEvents?.length > 0 && (
        <section className="section pt-5 pb-5 homepage-add-section">
          <Container>
            <SectionHeading
              heading="UPCOMING EVENTS"
              subHeading=""
            />
            <Row>
              {upcomingEvents.map((menu, index) => {
                return (
                  <Col md={6} xs={12} key={index}>
                    <div className="pb-2">
                      <ProductBox
                        id={menu.id}
                        onClick={() => handleMenuOnClick(menu)}
                        image={STORAGE_PATH + "/" + menu.image}
                        imageClass="img-fluid rounded-lg shadow"
                        imageAlt="product"
                        linkUrl="#"
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>
      )}
      
      {pastEvents?.length > 0 && (
        <section className="section pt-5 pb-5 homepage-add-section">
          <Container>
            <SectionHeading
              heading="PAST EVENTS"
              subHeading=""
            />
            <Row className="justify-content-center">
              {pastEvents.map((menu, index) => {
                return (
                  <Col md={8} xs={12} key={index}>
                    <div className="pb-2">
                      <ProductBox
                        id={menu.id}
                        // onClick={() => handleMenuOnClick(menu)}
                        image={STORAGE_PATH + "/" + menu.image}
                        imageClass="img-fluid rounded-lg shadow"
                        imageAlt="product"
                        linkUrl="#"
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>
      )}

      {/* <section className="section pt-5 pb-5 homepage-add-section bg-white">
        <Container>
          <Row>
            {shortcuts.map((shortcut, index) => {
              return (
                <Col md={6} xs={12} key={index}>
                  <div className="pb-2">
                    <ProductBox
                      id={shortcut.id}
                      onClick={() => handleShortCutOnClick(shortcut)}
                      image={STORAGE_PATH + "/" + shortcut.image}
                      imageClass="img-fluid rounded-lg shadow"
                      imageAlt="product"
                      linkUrl="#"
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section> */}
    </>
  );
};

const options = {
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
    1200: {
      items: 4,
    },
  },

  lazyLoad: true,
  pagination: false.toString(),
  loop: true,
  dots: false,
  autoPlay: 2000,
  nav: true,
  navText: [
    "<i class='fa fa-chevron-left'></i>",
    "<i class='fa fa-chevron-right'></i>",
  ],
};

export default withRouter(Index);
