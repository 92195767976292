import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Image } from "react-bootstrap";

class Error extends React.Component {
  render() {
    return (
      <section className="section pt-5 pb-5 osahan-not-found-page">
        <Container>
          <Row>
            <Col md={12} className="text-center pt-5 pb-5">
              <Image style={{ width: 150 }} className="img-fluid" src="/img/icons8-cancel-512.png" alt="404" />
              <h1 className="mt-2 mb-2">Payment Failed!</h1>
              <p className="mb-5">Something wrong with the payment or order information. Please retry.</p>
              <Link className="btn btn-primary btn-lg" to="/">
                Back to Home
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Error;
