import React, { useEffect, useState } from "react";
import { Row, Col, Container, Image, InputGroup, Form, Button, Card } from "react-bootstrap";
import { orderService } from "services";
import { useRecoilState } from "recoil";
import { eventDetailState } from "store/order";
import { Link } from 'react-router-dom';
import { STORAGE_PATH } from "../constant"
import BankOffers from "./common/BankOffers";
import NoDataMessage from "./common/NoDataMessage";
import Icofont from "react-icofont";
import { toast } from "react-toastify";
import Loading from "./common/Loading";

const Event = ({ match }) => {
    const [eventInfo, setEventInfo] = useRecoilState(eventDetailState);
    const [eventTickets, setEventTickets] = useState([]);
    const [eventDates, setEventDates] = useState([]);

    const [ipgType, setIpgType] = useState('default');

    const [purchTickets, setPurchTickets] = useState({});
    const [selectedDate, setSelectedDate] = useState("Please select");
    const [totalTickets, setSTotalTickets] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0.00);
    const [discount, setDiscount] = useState(0.00);

    const [discPres, setDiscPres] = useState(0);

    const [cusName, setCusName] = useState("");
    const [cusPhone, setCusPhone] = useState("");
    const [cusEmail, setCusEmail] = useState("");
    const [cusNic, setNic] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    const eventId = match.params.eventId;

    async function fetchData() {
        setPageLoading(true);
        Promise.all([
            orderService.getEventDetails(eventId),
        ]).then((data) => {
            setPageLoading(false);
            if (data[0] && data[0].status && data[0].status !== "ACTIVE") {
                window.location.href = "/";
                return;
            }
            setEventInfo(data[0]);

            setEventDates(data[0].dates && typeof data[0].dates == 'string' ? typeof JSON.parse(data[0].dates) == 'string' ? JSON.parse(JSON.parse(data[0].dates)) : JSON.parse(data[0].dates) : []);

            if (data[0]?.eventTicketMetas && data[0]?.eventTicketMetas.length > 0 && data[0]?.eventTickets && data[0]?.eventTickets.length > 0) {
                let tickPurchObj = {};

                let tickets2 = [];
                let tesat = data[0]?.eventTickets.forEach(element => {
                    if (element?.status == 'ACTIVE') {
                        tickets2.push(element)
                    }
                });

                let tickets = tickets2.map(ticket => {
                    tickPurchObj[ticket.id] = 0;
                    let found = data[0]?.eventTicketMetas.find(raw => raw.ticket_id == ticket.id);
                    return {
                        ...ticket,
                        ...found
                    };
                });
                
                setPurchTickets(tickPurchObj);
                
                setEventTickets(tickets);
            }
        }).catch((err) => {
            setPageLoading(false);
            setEventInfo(null);
        });
    }
    useEffect(() => {
        fetchData();
    }, [eventId]);

    useEffect(() => {
        console.log("purchTickets", purchTickets);
    }, [JSON.stringify(purchTickets)]);

    const submitForm = async (values) => {
        if (cusPhone.trim() == "" || cusPhone.trim() == "" || cusPhone.trim() == "" || cusPhone.trim() == "") {
            toast.error('Please enter valid data');
            return;
        }

        const phoneNumberPattern = /^\d{10}$/;
        if (phoneNumberPattern.test(cusPhone)) {
        } else {
            toast.error('Please enter valid phone number. Eg: 07XXXXXXXX');
            return;
        }

        const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (emailPattern.test(cusEmail)) {
        } else {
            toast.error('Please enter valid email. Eg: tickets@watersedge.lk');
            return;
        }

        const lettersAndSpacesPattern = /^[a-zA-Z\s]+$/;
        if (lettersAndSpacesPattern.test(cusName)) {
        } else {
            toast.error('Please enter valid name');
            return;
        }

        const nicPattern = /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/;
        if (nicPattern.test(cusNic)) {
        } else {
            toast.error('Please enter valid nic');
            return;
        }

        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (datePattern.test(selectedDate)) {
        } else {
            toast.error('Please valid date');
            return;
        }

        if (!purchTickets || !Object.values(purchTickets) || totalTickets == 0 || totalTickets < 0) {
            return;
        }

        setIsLoading(true);
        let tickets = [];
        Object.keys(purchTickets).map(purchTicket => {
            let found = eventTickets.find(ticket => ticket.ticket_id = purchTicket);

            if (purchTickets[purchTicket] && purchTickets[purchTicket] > 0) {
                tickets.push({
                    "ticket_id": Number(purchTicket),
                    "title": found?.title || '',
                    "quantity": purchTickets[purchTicket]
                });
            }

        });

        Promise.all([
            orderService.createOrder({
                "event_id": Number(eventId),
                "event_date": selectedDate,
                "ticket_purchases": tickets,
                "note": "",
                "cus_name": cusName,
                "cus_phone": cusPhone,
                "cus_email": cusEmail,
                "cus_nic": cusNic,
                "ipgType": ipgType
            }),
        ]).then((data) => {
            setIsLoading(false);

            if (data?.[0]?.ipgResp?.responseData?.paymentPageUrl)
                window.location = data?.[0]?.ipgResp?.responseData?.paymentPageUrl;
            else {
                toast.error('Something went wrong!');
            }

        }).catch((err) => {
            setIsLoading(false);
        });
    };

    const handleRemove = (id) => {
        let purchTicketClone = purchTickets;
        let totalTicketsClone = totalTickets;
        let totalPriceClone = totalPrice;
        if (id && purchTicketClone[id] != undefined && purchTicketClone[id] > 0) {
            const foundTicket = eventTickets.find(ticket => ticket.ticket_id == id);
            if (foundTicket) {
                purchTicketClone[id] = purchTicketClone[id] - 1;
                totalTicketsClone = totalTicketsClone - 1;
                totalPriceClone = totalPriceClone - foundTicket.price;

            }
        }

        setTotalPrice(totalPriceClone);
        setSTotalTickets(totalTicketsClone);
        setPurchTickets(prevState => ({
            ...prevState,
            [id]: purchTicketClone[id]
        }));
    };

    const handleAdd = (id) => {
        let purchTicketClone = purchTickets;
        let totalTicketsClone = totalTickets;
        let totalPriceClone = totalPrice;
        let totalDiscount = discount;
        if (id && purchTicketClone[id] != undefined) {
            const foundTicket = eventTickets.find(ticket => ticket.ticket_id == id);
            if (foundTicket) {
                purchTicketClone[id] = purchTicketClone[id] + 1;
                totalTicketsClone = totalTicketsClone + 1;
                totalPriceClone = totalPriceClone + foundTicket.price;
            }
        }

        setTotalPrice(totalPriceClone);
        setSTotalTickets(totalTicketsClone);
        setPurchTickets(prevState => ({
            ...prevState,
            [id]: purchTicketClone[id]
        }));
    };

    const calcTotals = () => {
        let discountTemp = (totalPrice * discPres);
        setDiscount(discountTemp);
    }

    useEffect(() => {
        calcTotals();
    }, [discPres, totalPrice]);

    const handleChange = ({ target: { value } }) => {
        console.log(value)
        setIpgType('promo');
        setDiscPres(0.15);
    };

    const handleChange2 = ({ target: { value } }) => {
        console.log(value)
        setIpgType('default')
        setDiscPres(0);
    };


    const renderTickets = () => {
        if (eventTickets.length > 0) {
            return eventTickets.map((eventTicket, index) => (
                <Col key={index} md={6}>
                    <BankOffers
                        title={eventTicket.title}
                        logoImage={`${STORAGE_PATH}/${eventTicket.image}`}
                        imageclassName="card-img"
                        subTitle={eventTicket.description}
                        ticket_id={eventTicket.ticket_id}
                        price={`Rs. ${eventTicket.price}`}
                        eventTicket={eventTicket}
                        handleRemove={() => handleRemove(eventTicket.ticket_id)}
                        handleAdd={() => handleAdd(eventTicket.ticket_id)}
                        quantity={purchTickets[eventTicket.ticket_id]}
                    />
                </Col>
            ));
        }

        return (
            <Col>
                <NoDataMessage message="No passes available at the moment. Please check back later." />
            </Col>
        );
    };

    return (
        <>
            {pageLoading ? (<></>) :
                (
                    <>
                        {false ? (
                            <section className="section pt-5 pb-5 osahan-not-found-page">
                                <Container>
                                    <Row>
                                        <Col md={12} className="text-center pt-5 pb-5 mt-5 mb-5">
                                            {/* <Image className="img-fluid" src="/img/404.png" alt="404" /> */}
                                            <h1 className="mt-2 mb-2 mt-5">Page not found</h1>
                                            <p>Uh-oh! Looks like the page you are trying to access, doesn't <br />exist. Please start afresh.</p>
                                            <Link className="btn btn-primary btn-lg" to="/">GO HOME</Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        ) : (
                            <>
                                <section className="section pb-2 ">

                                    {eventInfo?.event_image && eventInfo?.event_image !== '' && (
                                        <Image className="img-fluid3" src={STORAGE_PATH + "/" + eventInfo?.event_image} alt={eventInfo?.title} />
                                    )}


                                    <Container>
                                        <Row className="pt-4">
                                            <Col md={12}>
                                                <Card className="offer-card-horizontal border-0 shadow-sm mb-4">
                                                    <div className="card-body">
                                                        {eventInfo?.description && eventInfo?.description !== '' && JSON.parse(eventInfo?.description) && (
                                                            <div dangerouslySetInnerHTML={{ __html: JSON.parse(eventInfo?.description) }} />
                                                        )}

                                                    </div>
                                                </Card >
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            {
                                                purchTickets && eventTickets && eventTickets.length > 0 && eventTickets.length == 1 && eventTickets.map((eventTicket, index) => (
                                                    <Col key={index} md={12}>
                                                        <BankOffers
                                                            title={eventTicket.title}
                                                            logoImage={`${STORAGE_PATH}/${eventTicket.image}`}
                                                            imageclassName="card-img"
                                                            subTitle={eventTicket.description}
                                                            ticket_id={eventTicket.ticket_id}
                                                            price={`Rs. ${eventTicket.price}`}
                                                            eventTicket={eventTicket}
                                                            handleRemove={() => handleRemove(eventTicket.ticket_id)}
                                                            handleAdd={() => handleAdd(eventTicket.ticket_id)}
                                                            quantity={purchTickets[eventTicket.ticket_id]}
                                                        />
                                                    </Col>
                                                ))
                                            }
                                            {
                                                purchTickets && eventTickets && eventTickets.length > 0 && eventTickets.length > 1 && eventTickets.map((eventTicket, index) => (
                                                    <Col key={index} md={6}>
                                                        <BankOffers
                                                            title={eventTicket.title}
                                                            logoImage={`${STORAGE_PATH}/${eventTicket.image}`}
                                                            imageclassName="card-img"
                                                            subTitle={eventTicket.description}
                                                            ticket_id={eventTicket.ticket_id}
                                                            price={`Rs. ${eventTicket.price}`}
                                                            eventTicket={eventTicket}
                                                            handleRemove={() => handleRemove(eventTicket.ticket_id)}
                                                            handleAdd={() => handleAdd(eventTicket.ticket_id)}
                                                            quantity={purchTickets[eventTicket.ticket_id]}
                                                        />
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                        <Row className="pt-2">
                                            <Col md={6} xs={12}>

                                                <>

                                                    {eventDates && eventDates.length > 0 && (
                                                        <Form.Control
                                                            as="select"
                                                            name="event_date"
                                                            id="event_date"
                                                            placeholder="Event Date"
                                                            value={selectedDate}
                                                            onChange={(event) => setSelectedDate(event.target.value)}
                                                        >
                                                            <option value={""}>Select Event Date</option>
                                                            {eventDates?.map((date, index) => (
                                                                <option key={index} value={date} disabled={date == '2023-10-13' || date == '2023-10-12' || date == '2023-10-14'}>
                                                                    {date}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    )}
                                                    <Form.Control
                                                        name="cus_name"
                                                        id="cus_name"
                                                        type="text"
                                                        className="mt-2"
                                                        placeholder="Name"
                                                        onChange={(event) => setCusName(event.target.value.trim())}
                                                        value={cusName}
                                                    />
                                                    <Form.Control
                                                        name="cus_phone"
                                                        id="cus_phone"
                                                        type="text"
                                                        className="mt-2"
                                                        placeholder="Mobile Number ( Eg: 07XXXXXXXX )"
                                                        onChange={(event) => setCusPhone(event.target.value.trim())}
                                                        value={cusPhone}
                                                    />

                                                    <Form.Control
                                                        name="cus_email"
                                                        id="cus_email"
                                                        type="text"
                                                        className="mt-2"
                                                        placeholder="Email"
                                                        onChange={(event) => setCusEmail(event.target.value.trim())}
                                                        value={cusEmail}
                                                    />

                                                    <Form.Control
                                                        name="cus_nic"
                                                        id="cus_nic"
                                                        type="text"
                                                        className="mt-2"
                                                        placeholder="NIC"
                                                        onChange={(event) => setNic(event.target.value.trim())}
                                                        value={cusNic}
                                                    />
                                                </>
                                            </Col>
                                            <Col md={6} xs={12}>
                                                <Row className="pt-3">
                                                    {/* <Col md={6} xs={12}>
                                            <Form.Check
                                                key={1}
                                                custom
                                                type="radio"
                                                name="ipgType"
                                                id="ipgType"
                                                checked={ipgType == 'promo'}
                                                label={'HNB Credit Card (15% OFF)'}
                                                value={'promo'}
                                                className="font-weight-bold mb-3 mr-3"
                                                onChange={handleChange}
                                            />
                                        </Col> */}
                                                    <Col md={6} xs={12}>
                                                        <Form.Check
                                                            custom
                                                            type="radio"
                                                            name="ipgType"
                                                            id="ipgType2"
                                                            key={2}
                                                            checked={ipgType == 'default'}
                                                            label={'Credit/Debit Card'}
                                                            value={'default'}
                                                            className="font-weight-bold mb-3 mr-3"
                                                            onChange={handleChange2}
                                                        />
                                                    </Col>
                                                    <Col md={8} xs={6}>
                                                        <h5 className="font-weight-bold mt-0 mb-3">
                                                            Event Date
                                                        </h5>
                                                    </Col>
                                                    <Col md={4} xs={6}>
                                                        <h6 className="font-weight-bold mt-0 mb-3">
                                                            {`${selectedDate}`}
                                                        </h6>
                                                    </Col>

                                                    <Col md={8} xs={6}>
                                                        <h5 className="font-weight-bold mt-0 mb-3">
                                                            Total Passes
                                                        </h5>
                                                    </Col>
                                                    <Col md={4} xs={6}>
                                                        <h6 className="font-weight-bold mt-0 mb-3">
                                                            {`${totalTickets}`}
                                                        </h6>
                                                    </Col>

                                                    <Col md={8} xs={6}>
                                                        <h5 className="font-weight-bold mt-0 mb-3">
                                                            Subtotal
                                                        </h5>
                                                    </Col>
                                                    <Col md={4} xs={6}>
                                                        <h6 className="font-weight-bold mt-0 mb-3">
                                                            {`Rs. ${totalPrice}`}
                                                        </h6>
                                                    </Col>

                                                    <Col md={8} xs={6}>
                                                        <h5 className="font-weight-bold mt-0 mb-3">
                                                            Discount
                                                        </h5>
                                                    </Col>
                                                    <Col md={4} xs={6}>
                                                        <h6 className="font-weight-bold mt-0 mb-3">
                                                            {`Rs. ${discount}`}
                                                        </h6>
                                                    </Col>

                                                    <Col md={8} xs={6}>
                                                        <h5 className="font-weight-bold mt-0 mb-3">
                                                            Total to Pay
                                                        </h5>
                                                    </Col>
                                                    <Col md={4} xs={6}>
                                                        <h6 className="font-weight-bold mt-0 mb-3">
                                                            {`Rs. ${totalPrice - discount}`}
                                                        </h6>
                                                    </Col>
                                                    <Col md={12} xs={12}>
                                                        <InputGroup.Append>
                                                            <Button
                                                                variant="primary"
                                                                id="button-addon2"
                                                                className="btn-login"
                                                                type="submit"
                                                                onClick={submitForm}
                                                                disabled={isLoading || totalTickets == 0 || !selectedDate || selectedDate == "Please select" || !cusName || cusName == "" || !cusPhone || cusPhone == "" || !cusEmail || cusEmail == "" || !cusNic || cusNic == ""}
                                                            >
                                                                <Icofont icon="long-arrow-right" /> PROCEED TO PAY
                                                            </Button>
                                                        </InputGroup.Append>
                                                    </Col>

                                                </Row>

                                            </Col>

                                        </Row>

                                    </Container>
                                </section>

                                <section className="section pb-5 ">
                                    <Container>
                                        {eventInfo?.plan_img && eventInfo?.plan_img !== '' && (
                                            <Row className="pt-1 pb-1">
                                                <Col xs={12}>
                                                    <Image className="img-fluid2" src={STORAGE_PATH + "/" + eventInfo?.plan_img} alt={eventInfo?.title} />
                                                </Col>
                                            </Row>
                                        )}

                                        {eventInfo?.parking_plan_img && eventInfo?.parking_plan_img !== '' && (
                                            <Row className="pt-4">
                                                <Col xs={12}>
                                                    <Image className="img-fluid2" src={STORAGE_PATH + "/" + eventInfo?.parking_plan_img} alt={eventInfo?.title} />
                                                </Col>
                                            </Row>
                                        )}
                                    </Container>
                                </section>
                            </>
                        )}



                    </>
                )}
        </>

    );
};

export default Event;
