import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Image
} from "react-bootstrap";

const Footer = () => {
  return (
    <>
      <section className="section pt-2 pb-2 text-center bg-white">
        <Container>

        <Image style={{maxWidth: 150}} className="img-fluid" src="/img/logo.png" alt="WE logo" />
          <Row>
            {/* <Col sm={12}>
              <h5 className="m-0">
              Hotline { " "}
                <Link to="login">+94 70 600 3344</Link>
              </h5>
            </Col> */}
          </Row>
        </Container>
      </section>
      <footer className="pb-4 text-center bg-white">
        <Container>
          {/* <p>
            <Link to="/terms-conditions">Terms & Conditions</Link>&#160;|&#160;
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p> */}
          <p className="mt-0 mb-0">
            © 2023 <a href="http://www.vision8.io/">VISION8</a>. All Rights
            Reserved.
          </p>
        </Container>
      </footer>
    </>
  );
};

Footer.propTypes = {
  sectionclassName: PropTypes.string,
  popularCHclassName: PropTypes.string,
  popularCountries: PropTypes.array,
  popularFHclassName: PropTypes.string,
  popularFood: PropTypes.array,
  copyrightText: PropTypes.string,
  madewithIconclassName: PropTypes.string,
  firstLinkText: PropTypes.string,
  firstLink: PropTypes.string,
  secondLinkText: PropTypes.string,
  secondLink: PropTypes.string,
};

Footer.defaultProps = {
  sectionclassName: "footer-bottom-search pt-5 pb-5 bg-white",
  popularCHclassName: "text-black",
  popularCountries: [],
  popularFHclassName: "mt-4 text-black",
  popularFood: [],
  copyrightText: "© 2021-2022 VISION8. All Rights Reserved",
  madewithIconclassName: "heart heart-icon text-danger",
  firstLinkText: "Gurdeep Osahan",
  firstLink: "//www.instagram.com/iamgurdeeposahan/",
  secondLinkText: "Askbootstrap",
  secondLink: "//askbootstrap.com/",
};

export default Footer;
