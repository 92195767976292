import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Image } from "react-bootstrap";
import Select2 from "react-select2-wrapper";
import Icofont from "react-icofont";
import OwlCarousel from "react-owl-carousel3";
import ProductBox from "./ProductBox";
import { COVER, OFFER } from "assets/img";
import CategoriesCarousel from "../common/CategoriesCarousel";
import { useRecoilState, useRecoilValue } from "recoil";
import { bannersListState } from "store/meta";
import {STORAGE_PATH} from "../../constant"

const TopSearch = () => {
  const banners = useRecoilValue(bannersListState);
  const [mainBanner, setMainBanner] = useState(COVER);

  useEffect(() => {
    if (banners && banners.length > 0) {
      const found = banners.find((banner) => banner.type === "MAIN_BANNER");
      if (found && found.image) {
        setMainBanner(STORAGE_PATH + "/" + found.image);
      }
    }
  }, [banners]);

  return (
    <section
      className=""
      // style={{
      //   backgroundImage: `url('${mainBanner}')`,
      //   backgroundSize: "contain",
      //   backgroundPosition: "center",
      //   backgroundRepeat: 'no-repeat'
      // }}
    >
      <Image className="img-fluid" src={mainBanner} />
    </section>
  );
};

const options2 = {
  responsive: {
    0: {
      items: 2,
    },
    764: {
      items: 2,
    },
    765: {
      items: 1,
    },
    1200: {
      items: 1,
    },
  },
  lazyLoad: true,
  loop: true,
  autoplay: true,
  autoplaySpeed: 1000,
  dots: false,
  autoplayTimeout: 2000,
  nav: true,
  navText: [
    "<i class='fa fa-chevron-left'></i>",
    "<i class='fa fa-chevron-right'></i>",
  ],
  autoplayHoverPause: true,
};

export default TopSearch;
