import React from 'react';
import { Image, Row, Col, Card, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from "react-icofont";

class BankOffers extends React.Component {

	render() {
		return (
			<Card className="offer-card-horizontal border-0 shadow-sm mb-4">
				<Row className="d-flex align-items-center no-gutters">

					<Col md={9} xs={12}>
						<div className="card-body">
							<h5 className="card-title">{this.props.title}
							</h5>
							{this.props.subTitle ?
								<p className="card-text">
									{this.props.subTitle}
								</p>
								: ""
							}
						</div>

					</Col>
					<Col md={3} xs={12} className="p-4">
						<h6 className="card-price">{this.props.price}
						</h6>
						<div
							className="count-number float-center"
							style={{ paddingRight: 5 }}
						>
							<Button
								variant="outline-secondary"
								onClick={this.props.handleRemove}
								className="btn-lg left dec"
							>
								{" "}
								<Icofont icon="minus" />{" "}
							</Button>
							<input
								className="count-number-input"
								type="text"
								value={this.props.quantity || 0}
								readOnly
							/>
							<Button
								variant="outline-secondary"
								onClick={this.props.handleAdd}
								className="btn-lg right inc"
							>
								{" "}
								<Icofont icon="icofont-plus" />{" "}
							</Button>

						</div>
					</Col>
				</Row>
			</Card >
		);
	}
}

BankOffers.propTypes = {
	title: PropTypes.string.isRequired,
	logoImage: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	imageAlt: PropTypes.string,
	imageclassName: PropTypes.string,
	handleAdd: PropTypes.any,
	quantity: PropTypes.any,
	handleRemove: PropTypes.any,
	price: PropTypes.string,
};
BankOffers.defaultProps = {
	subTitle: '',
	imageAlt: '',
	imageclassName: '',
}

export default BankOffers;